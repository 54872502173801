<template>
    <div class="pg" id="box">
        <Top :topIndex="4"  @changeL="changeL"></Top>
        <div style="min-height:10vh">
            <img :src="cn_enobj.site.index.home_logo"  class="zhanweiImg" alt="">
        </div>
        <!-- 加入我们详情 -->
        <div class="contactUs-item2" v-show="showItem2">
            <div class="contactUs-item2-box2">
                <div  class="contactUs-item2-box2-btn" @click="comeback()">
                    <img src="@/assets/img/返回.png" alt="">
                    <span style="color: #01AE0D;font-size: 2vmin;">{{cn_enobj.site.components.bottom.back_bottom}}</span>
                </div>
            </div>
            <div class="contactUs-item2-box">
                <div class="card-1">{{locale == 'zh'?joinDetail.name:joinDetail.en_name}}</div>
                <div class="card-2">
                    <span>{{locale == 'zh'?joinDetail.category:joinDetail.en_category}}</span>
                    <span>{{locale == 'zh'?joinDetail.experience:joinDetail.en_experience}}</span>
                    <span>{{locale == 'zh'?joinDetail.address:joinDetail.en_address}}</span>
                </div>
                <div class="card-3" style="margin-top:20px">
                    <!-- 岗位职责 -->
                    <div class="card-3-title">{{cn_enobj.site.contactUs.join.content.duty}}</div>
                    <div class="card-3-body">
                        <div v-html="locale == 'zh'?joinDetail.duty:joinDetail.en_duty"></div>
                    </div>
                </div>
                <div class="card-3" style="margin-top:20px">
                    <!-- 岗位要求 -->
                    <div class="card-3-title">{{cn_enobj.site.contactUs.join.content.job_require}}</div>
                    <div class="card-3-body">
                        <div v-html="locale == 'zh'?joinDetail.job_require:joinDetail.en_job_require"></div>
                    </div>
                </div>
                <div class="txbk" style="margin-top:20px">info@haocenebcapital.com.cn</div>
            </div>
            
        </div>
        <!-- 加入我们 -->
        <div class="contactUs-item3" v-show="!showItem2 && cn_enobj.site.contactUs.join.is_show">
            <div class="labelsTop" >
                <div style="display: flex;"   class="reveal-r" >
                    <div style="text-align: center;">
                        <div class="labels">{{cn_enobj.site.contactUs.join.title}}</div>
                        <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.contactUs.join.subtitle}}</div>
                    </div>
                </div>
            </div>
            <div class="contactUs-item3-box">
                 <div class="contactUs-item3-card reveal-bottom" v-for="(i,index) in list" :key="index" 
                 @click="openDetaile(index,i)">
                    <div class="card-1">{{locale == 'zh'?i.name:i.en_name}}</div>
                    <div class="card-2">
                        <span>{{locale == 'zh'?i.category:i.en_category}}</span>
                        <span>{{locale == 'zh'?i.experience:i.en_experience}}</span>
                        <span>{{locale == 'zh'?i.address:i.en_address}}</span>
                    </div>
                    <div class="card-3" style="margin-top:20px">
                        <!-- 岗位职责 -->
                        <div class="card-3-title">{{cn_enobj.site.contactUs.join.content.duty}}</div>
                        <div class="card-3-body1">
                            <div  v-html="locale == 'zh'?i.duty:i.en_duty"></div>
                        </div>
                    </div>
                    <div class="imgbtn" style="margin-top:20px;margin-bottom: 0vh">JSON US</div>
                </div>
            </div>
            <div class="loadMore">
                <div class="loadMore-btn reveal-r"  v-if="list.length>8"  @click="loadMoreEV()">{{cn_enobj.site.components.bottom.more_bottom}}</div>
            </div>
        </div>
        <!-- 联系我们 -->
        <div class="contactUs-item4"  v-show="!showItem2 && cn_enobj.site.contactUs.contact.is_show">
            <div class="labelsTop" style="padding-top:5vh">
                <div style="display: flex;"   class="reveal-r" >
                    <div style="text-align: center;">
                        <div class="labels">{{cn_enobj.site.contactUs.contact.title}}</div>
                        <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.contactUs.contact.subtitle}}</div>
                    </div>
                </div>
            </div>
            <!-- @ready="handler" -->
            <div  class="contactUs-item4-box  reveal-bottom">
               
                <baidu-map class="map" 
                    :center="map.center"
                    :zoom='15'
                    :scroll-wheel-zoom='true'>
                        <bm-geolocation
                        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                        :showAddressBar="true"
                        :autoLocation="true"
                    ></bm-geolocation>
                    <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
                    <bm-marker :position="map.center" :dragging="true" animation='BMAP_ANIMATION_BOUNCE'>
                        <bm-label :content='cn_enobj.site.contactUs.contact.content.company_address' :labelStyle="labelStyle" :offset="offset" />
                    </bm-marker>
                </baidu-map>
                <div class="contactUs-item4-box-right">
                    <div class="right1">
                        <div class="title"></div>
                       
                        <div>
                            <div class="lable">{{cn_enobj.site.contactUs.contact.content.official_number.title}}</div>
                            <div class="mail">{{cn_enobj.site.contactUs.contact.content.official_number.subtitle}}</div>
                        </div>
                        <div><img :src="cn_enobj.site.contactUs.contact.content.official_number.qrcode" style="width:12vmin;height:12vmin;margin-left:2vw" alt=""></div>
                    </div>
                    <div class="right2">
                        <div class="title"></div>
                        <div style="width:100%">
                            <div class="lable">{{cn_enobj.site.contactUs.contact.content.corporation_mailbox.title}}</div>
                            <div class="mail" style="word-wrap: break-word; word-break:break-all;">{{cn_enobj.site.contactUs.contact.content.corporation_mailbox.mailbox}}</div>
                        </div>
                    </div>
                    <div class="right3">
                        <div class="title"></div>
                        <div>
                            <!-- 办公地址：（上海市） -->
                            <div class="lable" style="margin-bottom:3vh">{{cn_enobj.site.contactUs.contact.content.company_office}}</div>
                            <div class="mail">
                                <!-- 地址：中国上海浦东大道陆家嘴外滩一号 -->
                                <div> {{cn_enobj.site.contactUs.contact.content.company_address}}</div>
                                <!-- 邮箱：200120 -->
                                <div>{{cn_enobj.site.contactUs.contact.content.postal_code}}</div> 
                                <!-- 电话：+86（21）45612156 -->
                                <div>{{cn_enobj.site.contactUs.contact.content.company_phone }}</div>
                                <!-- 传真：+86（21）45612156 -->
                                <div>{{cn_enobj.site.contactUs.contact.content.company_fax}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        
            <div class="contactUs-item4-showbox  reveal-bottom">
            
                <img :src="cn_enobj.site.contactUs.contact.content.official_number.qrcode" 
                style="width:140px;height:140px;margin-bottom:2vw" alt="">
                <div class="lable">{{cn_enobj.site.contactUs.contact.content.official_number.title}}</div>
                <div class="mail" >
                    {{cn_enobj.site.contactUs.contact.content.official_number.subtitle}}
                </div>
                
            
                <div class="showbox-title"></div>
                <div class="lable">{{cn_enobj.site.contactUs.contact.content.corporation_mailbox.title}}</div>
                <div class="mail" style="word-wrap: break-word; word-break:break-all;">
                    {{cn_enobj.site.contactUs.contact.content.corporation_mailbox.mailbox}}
                </div>

                <div class="showbox-title"></div>
                <!-- 办公地址：（上海市） -->
                <div class="lable" style="margin-bottom:2vh">
                    {{cn_enobj.site.contactUs.contact.content.company_office}}
                </div>
                <!-- 地址：中国上海浦东大道陆家嘴外滩一号 -->
                <div class="mail"  style="width:70%; text-align: center; word-wrap: break-word; word-break:break-all;"> {{cn_enobj.site.contactUs.contact.content.company_address}}</div>
                <!-- 邮箱：200120 -->
                <div class="mail" >{{cn_enobj.site.contactUs.contact.content.postal_code}}</div> 
                <!-- 电话：+86（21）45612156 -->
                <div class="mail" >{{cn_enobj.site.contactUs.contact.content.company_phone }}</div>
                <!-- 传真：+86（21）45612156 -->
                <div class="mail" >{{cn_enobj.site.contactUs.contact.content.company_fax}}</div>
                
            </div>
        </div>
        <Bottom></Bottom>
        <ReturnTop></ReturnTop>
    </div>
</template>

<script>
import Top from '@/components/top.vue'
import Bottom from '@/components/bottom.vue'
import ReturnTop from '@/components/returnTop.vue'
import scrollReveal from 'scrollreveal'
export default {  
    components:{Top,Bottom,ReturnTop},
    data(){
        return{
            scrollReveal: scrollReveal(),
            map:{
                center: {lng: 121.562753, lat: 31.247916},
                zoom: 15,
                show: true,
                dragging: true
            },
            labelStyle: {color:'red',fontSize:'12px'},
            offset:{width:-35,height:30},
            list : [],
            isDisable:false, 
            showItem2:false,// 是否显示item2
            locale:'',
            page:1,
            joinDetail:{}, // 加入我们详情
            cn_enobj:{}
        }
    },
     //项目中执行，自定义类名。
    mounted() {
        this.scrollReveal.reveal('.reveal-bottom', {origin: 'bottom',...this.global});
        this.scrollReveal.reveal('.reveal-r', {...this.global,distance: '0px', scale: 0.9});
    },
    created(){
        this.getRecruitmentData()
        this.changeL(this.$i18n.locale)
    },
    methods:{
        // /api/content/list/recruitment
        // 获取新闻中心列表数据
        getRecruitmentData(){
            this.$axios({
                method:'get',
                url:'/api/content/list/recruitment?page='+this.page,
            }).then(res=>{
                if(this.page !== 1 && res.data.data.data.length === 0 ){
                    this.$message({
                        message: '没有更多数据了！',
                        type: 'warning'
                    });
                    this.isDisable = true
                    return
                }
                this.isDisable = false
                this.list = this.list.concat(res.data.data.data)
            })  
        },
        changeL(str){
            this.locale = str
            this.cn_enobj = this.locale == 'zh' ?JSON.parse(sessionStorage.getItem("cnobj")):JSON.parse(sessionStorage.getItem("enobj"))
            // console.log('55',this.cn_enobj.site.contactUs.contact.content.company_position)
            // let lng=this.cn_enobj.site.contactUs.contact.content.company_position.lng
            // let lat=this.cn_enobj.site.contactUs.contact.content.company_position.lat
            this.map = {
                center:this.cn_enobj.site.contactUs.contact.content.company_position,// {lng:lng,lat:lat},
                zoom: 15,
                show: true,
                dragging: true
            }
            // console.log('map',this.map)
        },
        // 打开详情
        openDetaile(index,val){
            this.$axios({
                method:'get',
                url:'/api/content/detail/recruitment?id='+val.id
            }).then(res=>{
                this.joinDetail = res.data.data
                this.showItem2 = true
                this.$scrollFun()
            }) 
        },
        // 关闭详情
        comeback(){
            this.showItem2 = false
            this.$scrollFun()
        },
        // handler ({BMap, map}) {
        //     let me = this;
        //     console.log(BMap, map)
        //     // 鼠标缩放
        //     map.enableScrollWheelZoom(true);
        //     // 点击事件获取经纬度
        //     map.addEventListener('click', function (e) {
        //         console.log(e.point.lng, e.point.lat)
        //     })
        // },
        // handler({ BMap, map }) {
        //     const that = this;
        //     var geolocation = new BMap.Geolocation();
        //     geolocation.getCurrentPosition(function(r) {
        //         if (this.getStatus() == BMAP_STATUS_SUCCESS) {
        //         that.map.center.lng = r.longitude;
        //         that.map.center.lat = r.latitude;
        //         console.log(that.map.center);
        //         } else {
        //         alert("failed" + this.getStatus());
        //         }
        //     });
        // },
        loadMoreEV(){
            if(this.isDisable)return
            this.page =this.page+1
            this.getRecruitmentData()
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../src/assets/css/pc/contactUs_pc.less"; 
</style>